<template>
    <div>
        <b-container>
            <section class="jobs">
                <div class="sector pb-0">
                    <h2 class="main-title">Job Offers</h2>
                    <p class="main-subtitle">Reach for the opportunity</p>
                    <b-row>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="filterJob" @click.prevent="clearFilter" class="clear-icon clear-icon--filter">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <input v-model="filterJob" class="custom-search-bar" type="text" placeholder="Search by job title or city or country" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedForm" @click.prevent="clearType" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedForm" class="custom-sorter" labelTitle = "Sort by form of employment" v-model="selectedForm" :options="forms" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedCountry" @click.prevent="clearCountry" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedCountry" class="custom-sorter" labelTitle = "Sort by country" v-model="selectedCountry" :options="countries" :searchable = true />
                            </div>
                        </b-col>
                    </b-row>
                    <div v-if="getFilteredJobs.length == 0 && loader" class="mb-3">
                        <Spinner size="medium" line-fg-color="#e91e63" />
                    </div>
                    <div id="top" v-else>
                        <div v-if="filterJob != '' || selectedCountry != null || selectedForm != null">
                            <paginate name="jobsStore" :list="getFilteredJobs" tag="div" :per="10" class="paginate-list">
                                <b-row>
                                    <b-col v-for="job in paginated('jobsStore')" :key="job.id" cols="12" lg="6">
                                        <div class="jobs__element">
                                            <div v-if="job.ribbon" class="custom-ribbon custom-ribbon--right">
                                                <span class="custom-ribbon__text custom-ribbon__text--green">Featured</span>
                                            </div>
                                            <b-row>
                                                <b-col cols="10" md="8" lg="6" xl="8">
                                                    <p class="jobs__form">{{ job.type.text }}</p>
                                                    <h2 class="jobs__position" v-line-clamp:22="1">{{ job.position }}</h2>
                                                    <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Company"><simple-line-icons icon="briefcase" size="small" color="#504e70" /></span>{{ job.company }}</span>
                                                    <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ job.city }}, {{ job.country.text }}</span>
                                                </b-col>
                                                <b-col cols="12" md="4" lg="6" xl="4" style="display: flex;">
                                                    <router-link class="details-button details-button--bottom" :to="{ name: 'job', params: { id: job.id }}"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</router-link>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                            </paginate>
                            <paginate-links @change="scrollToTop('top')" v-if="getFilteredJobs.length != 0" for="jobsStore" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                        </div>
                        <div v-else>
                            <paginate name="jobList" :list="getFilteredJobs" tag="div" :per="10" class="paginate-list">
                                <b-row>
                                    <b-col v-for="job in paginated('jobList')" :key="job.id" cols="12" lg="6">
                                        <div class="jobs__element">
                                            <div v-if="job.ribbon" class="custom-ribbon custom-ribbon--right">
                                                <span class="custom-ribbon__text custom-ribbon__text--green">Featured</span>
                                            </div>
                                            <b-row>
                                                <b-col cols="10" md="8" lg="6" xl="8">
                                                    <p class="jobs__form">{{ job.type.text }}</p>
                                                    <h2 class="jobs__position" v-line-clamp:22="1">{{ job.position }}</h2>
                                                    <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Company"><simple-line-icons icon="briefcase" size="small" color="#504e70" /></span>{{ job.company }}</span>
                                                    <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ job.city }}, {{ job.country.text }}</span>
                                                </b-col>
                                                <b-col cols="12" md="4" lg="6" xl="4" style="display: flex;">
                                                    <router-link class="details-button details-button--bottom" :to="{ name: 'job', params: { id: job.id }}"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</router-link>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                            </paginate>
                            <paginate-links @change="scrollToTop('top')" v-if="getFilteredJobs.length != 0" for="jobList" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                        </div>
                    </div>
                    <div v-if="getFilteredJobs.length == 0 && getJobsPending && !loader" class="mb-3">
                        <p class="text-center result-text">No results found</p>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import countriesList from '../assets/files/countriesList.js'
import jobFormTypes from '../assets/files/jobFormTypes.js'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import VuePaginate from 'vue-paginate'
import MoveTo from 'moveto'
export default {
  components: {
    SimpleLineIcons,
    VSelect,
    Spinner
  },
  data: function () {
    return {
      loader: true,
      selectedCountry: null,
      selectedForm: null,
      countries: [],
      forms: [],
      filterJob: '',
      paginate: ['jobList', 'jobsStore']
    }
  },
  methods: {
    clearType () {
      this.selectedForm = null
      if (document.querySelector('#selectedForm li.selected') != undefined) {
        document.querySelector('#selectedForm li.selected').classList.remove('selected')
      }
    },
    clearCountry () {
      this.selectedCountry = null
      if (document.querySelector('#selectedCountry li.selected') != undefined) {
        document.querySelector('#selectedCountry li.selected').classList.remove('selected')
      }
    },
    clearFilter () {
      this.filterJob = ''
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    }
  },
  computed: {
    ...mapGetters([
      'getJobs',
      'getJobsPending'
    ]),
    getStoreJobs () {
      var storeJobs = Object.values(this.getJobs).reverse().sort((a, b) => b.ribbon - a.ribbon)
      return storeJobs
    },
    getFilteredJobs () {
      if (this.filterJob != '' || this.selectedCountry != null || this.selectedForm != null) {
        var jobs = Object.values(this.getStoreJobs).filter((job) => {
          this.paginate.jobsStore.page = 0
          return (job.position.toLowerCase().includes(this.filterJob.toLowerCase()) || job.city.toLowerCase().includes(this.filterJob.toLowerCase()) || job.country.text.toLowerCase().includes(this.filterJob.toLowerCase()))
        })

        this.paginate.jobsStore.page = 0

        if (this.$route.query.jobParam != undefined) {
          window.history.replaceState(null, '', this.$route.path + '?page=' + 1 + '&jobParam=' + this.$route.query.jobParam)
        } else {
          window.history.replaceState(null, '', this.$route.path + '?page=' + 1)
        }

        if (this.selectedCountry != null && this.selectedForm == null) {
          return jobs.filter((jobs) => {
            return jobs.country.text.toLowerCase() == this.selectedCountry.value
          })
        } else if (this.selectedCountry == null && this.selectedForm != null) {
          return jobs.filter((jobs) => {
            return jobs.type.text.toLowerCase() == this.selectedForm.value
          })
        } else if (this.selectedCountry != null && this.selectedForm != null) {
          return jobs.filter((jobs) => {
            return jobs.country.text.toLowerCase() == this.selectedCountry.value && jobs.type.text.toLowerCase() == this.selectedForm.value
          })
        } else {
          return jobs
        }
      } else {
        var jobs = Object.values(this.getStoreJobs)
        return jobs
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('getJobs')
  },
  created () {
    if (this.$route.query.jobParam != undefined) {
      this.filterJob = this.$route.query.jobParam
    }

    this.countries = countriesList
    this.forms = jobFormTypes

    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
