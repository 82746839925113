<template>
    <div>
        <JobsList />
    </div>
</template>

<script>
import JobsList from '@/components/JobsList.vue'
export default {
  components: {
    JobsList
  }
}
</script>
